import { createStore } from "vuex";

export default createStore({
	state: {
		messageList: [],
		reportID: "",
		docClass: {
			parent: [],
			son: [],
		},
		canLoadMessage: true,
		alreadyLoaded: false,
		lotteryData: {
			lotteryDrawTimes: 0,
			signInDays: 0,
			totalRewardPoints: "0",
			allowSignIn: 1,
			lotteryDrawCfg: {},
			signInCfg: {},
		},
		isLoad: false,
		appDetailID: 0,
		appReinforceID: 0,
		appReinforceEditPlugID: 0,
		appReinforceInfo: {},
		cfg: {
			io: {
				// apps: 1,
				// verify: 1,
				// sign: 1,
				// superSign: 1,
				// ios: 1,
				// ipa: 1,
				// android: 1,
				// flight: 1,
				// pack: 1,
				// wxPay: 1,
				// aliPay: 1,
				apps: 0,
				verify: 0,
				sign: 0,
				superSign: 0,
				ios: 0,
				ipa: 0,
				android: 0,
				flight: 0,
				pack: 0,
				wxPay: 0,
				aliPay: 0,
			},
			distribute: {
				dailyTimes: 0,
				uploadPackMax: "0",
			},
			price: {
				distribute: 0,
				package: 0,
				enterprise: 0,
				machine: 0,
				iosSign: 0,
				packageItems: [
					{
						expiration: "",
						priceA: "",
						priceB: "",
					},
					{
						expiration: "",
						priceA: "",
						priceB: "",
					},
					{
						expiration: "",
						priceA: "",
						priceB: "",
					},
					{
						expiration: "",
						priceA: "",
						priceB: "",
					},
					{
						expiration: "",
						priceA: "",
						priceB: "",
					},
				],
				distributeItems: [
					{
						number: 0,
						price: 0,
					},
					{
						number: 0,
						price: 0,
					},
					{
						number: 0,
						price: 0,
					},
				],
				vipItems: [
					{
						title: "",
						price: 0,
						priceTxt: "",
					},
					{
						title: "",
						price: 0,
						priceTxt: "",
					},
					{
						title: "",
						price: 0,
						priceTxt: "",
					},
				],
			},
			tutorial: {
				videoUrl: "",
				imageUrl: "",
				pageUrl: "",
			},
			suggestions: [
				{
					icon: "",
					name: "",
					job: "",
					content: "",
					logo: "",
				},
			],
			links: [
				{
					title: "",
					logo: "",
					url: "",
				},
			],
			contact: {
				qq: "",
				phone: "",
				emailA: "",
				wxCode: "",
				emailB: "",
				address: "",
				workingHours: "",
			},
			kf: {
				working: "",
				online: "",
				qq: "",
				wxCode: "",
			},
			appCases: [
				{
					title: "",
					image: "",
				},
			],
			copyright: "",
			cdnUrl: "",
			webSite: {
				title: "",
				keywords: "",
				description: "",
				icp: "",
				ganIcp: "",
				copyright: "",
				creditCode: "",
			},
			cases: [],
			service: {
				serviceAgreement: "",
				privacyPolicy: "",
				appAuditSpecs: "",
				freePointsNotes: "",
				feePointsNotes: "",
			},
		},
	},
	getters: {},
	mutations: {
		setVal(state, option) {
			state[option.key] = option.value;
		},
	},
	actions: {},
	modules: {},
});

import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		path: "/",
		redirect: "/copyright"
	},
	{
		path: "/icp",
		name: "icp",
		component: () => import("../views/icp.vue"),
	},
	{
		path: "/edi",
		name: "edi",
		component: () => import("../views/edi.vue"),
	},
	{
		path: "/copyright",
		name: "copyright",
		component: () => import("../views/copyright.vue"),
	},
	{
		path: "/game",
		name: "game",
		component: () => import("../views/gameVerNum.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;

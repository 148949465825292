import "ant-design-vue/dist/antd.css"; // or 'ant-design-vue/dist/antd.less'
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./locales/index";
import 'babel-polyfill';
import AntV from "ant-design-vue";
import "ant-design-vue/dist/antd.css"; // or 'ant-design-vue/dist/antd.less'
// const logs = console.log
// console.log = () => {}

createApp(App).use(store).use(AntV).use(router).use(i18n).mount("#app");
